































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import { createLegacyUser, customerPortal, getInvoiceData, getLegacyUpcomingInvoice, getShopUser } from '@/api/shop'
import { get } from 'lodash'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { getCards } from '@/api/shop/cards'
import VsPaymentMethodCard from '@/modules/account/components/VsPaymentMethodCard/Index.vue'
import VsInvoiceData from '@/components/VsInvoiceData/Index.vue'
import VsPlanDetails from '@/modules/account/components/VsPlanDetails/Index.vue'
import VsCartLineItem from '@/modules/shop/components/VsCartLineItem/Index.vue'
import { isLegacyPlanToConvert } from '@/permission'
import { formatNumber } from '@/utils/formatter'
import { formatAddress, formatEmailCreditsRateFromMetadata } from '@/utils/shop'
import moment from 'moment'
@Component({
    name: 'AutomaticPayment',
    components: {
        VsContainer,
        VsFullTopBarLayout,
        VsPaymentMethodCard,
        VsInvoiceData,
        VsCartLineItem,
        VsPlanDetails,
        VsLoader,
    },
})
export default class extends Vue {
    step: 'home' | 'invoiceData' | 'paymentMethod' | 'checkout' | 'success' = 'home'
    customer: any = null
    showInvoiceData = false
    defaultPaymentMethod = ''
    cards: any[] = []
    loadingCustomer = false
    loadingCards = false
    loadingUpcoming = false
    showDetails = false
    loadingUpdate = false
    upcoming: any = null
    acceptTerms = false

    get shopUser () {
        return UserModule.shopUser
    }

    get user () {
        return UserModule.user
    }

    get isXlViewport () {
        return AppModule.isXlViewport
    }

    get defaultCard () {
        return this.cards.find(el => el.id === this.defaultPaymentMethod)
    }

    get rules () {
        return {
            ...this.user.configuration.rulesV2,
        }
    }

    get updatedRecursivity () {
        if (this.upcoming && this.upcoming.lines.data.length === 0) return false
        let recursivity: any = false
        for (const line of this.upcoming.lines.data) {
            if (line.price.type === 'recurring') {
                recursivity = {
                    type: line.price.recurring.interval + 's',
                    value: line.price.recurring.interval_count,
                }
                break
            }
        }
        return recursivity
    }

    get userAddress () {
        if (!this.customer) return ''
        return formatAddress(this.customer.address)
    }

    beforeMount () {
        if (!isLegacyPlanToConvert()) {
            this.$router.replace({ name: 'dashboard' })
        }
        if (this.$route.query?.step === 'payment-method' && this.shopUser?.customerId) {
            this.step = 'paymentMethod'
            this.$router.replace({ query: undefined })
        }
        this.getCustomer()
        this.geCards()
    }

    private async getCustomer () {
        this.loadingCustomer = true
        try {
            const resp = await getInvoiceData()
            this.customer = resp.data
            this.defaultPaymentMethod = get(this.customer, 'defaultPaymentMethod', '')
        } catch (e) {
            console.log(e)
            this.customer = null
            this.defaultPaymentMethod = ''
        }
        this.loadingCustomer = false
    }

    private async editPaymentMethod () {
        try {
            const resp = await customerPortal({
                returnUrl: window.location.href.split('?')[0] + '?step=payment-method',
            })

            const url = this.shopUser?.subscription ? `${resp.data.url}/subscriptions/${this.shopUser.subscription.foreignId}/update-payment-method/changePaymentMethodFromHome` : resp.data.url

            window.location.href = url
        } catch (e) {
            const statusCode = get(e, 'response.status', '')
            if (statusCode === 403) {
                this.$root.$vsToast({
                    heading: 'Non hai i permessi per completare questa azione',
                    aspect: VsToastAspectEnum.alert,
                    timeout: 3000,
                })
            }
            console.log(e)
        }
    }

    private async geCards () {
        this.loadingCards = true
        try {
            const resp = await getCards()
            this.cards = resp.data
        } catch (e) {
            console.log(e)
        }
        this.loadingCards = false
    }

    private async updateInvoiceData () {
        this.showInvoiceData = false
        await this.getCustomer()
        await this.getShopUser()
    }

    async setCheckoutStep () {
        this.step = 'checkout'
        this.getLegacyUpcomingInvoice()
    }

    async getLegacyUpcomingInvoice () {
        this.loadingUpcoming = true
        try {
            const resp = await getLegacyUpcomingInvoice()
            console.log(resp.data)
            this.upcoming = resp.data
        } catch (e) {
            console.log(e)
        }
        this.loadingUpcoming = false
    }

    private formatEmailCreditsRateFromPrice (price: any) {
        return formatNumber(parseInt(price.metadata.rateCredits)) + ' email /' + formatEmailCreditsRateFromMetadata(price.metadata)
    }

    openDetails () {
        this.showDetails = true
    }

    async updateSubscription () {
        if (!this.user.startAtLegacy) return
        this.loadingUpdate = true
        try {
            const resp = await createLegacyUser({
                // startAt: moment(this.user.startAtLegacy).unix(),
                startAt: moment(this.user.endAt).subtract({ years: 1 }).unix(),
            })

            console.log(resp.data)
            UserModule.SET_IS_LEGACY_USER(false)
            this.step = 'success'
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'attivazione del pagamento automatico',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loadingUpdate = false
    }

    async getShopUser () {
        try {
            const resp = await getShopUser()
            UserModule.SET_SHOP_USER(resp.data)
        } catch (e) {
            console.log(e)
        }
    }
}
